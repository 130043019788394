<template>
  <v-sheet :color="wsLIGHTCARD" class="wsRoundedHalf ">

    <div class="pa-6 pt-1">

      <v-img class="ml-n2 mt-3 mb-3"  width="64" height="64"  :src="require(`@/assets/illustrations/pricing_${index + 1}.png`)" />

      <h3 :style="`color : ${wsDARKER}`">{{ $t(`homepage.pricing.plans.${plan.value}.title`) }}</h3>
      <h1 style="font-size: 42px" class="d-flex align-center mt-2">
        ${{plan.price}} <div class="ml-1 font-weight-regular" style="font-size: 16px"> / {{ $t('perMonth') }}  </div>
      </h1>

      <v-divider class="my-4 mb-6" style="border-width: 1px" :style="`border-color : ${wsBACKGROUND}`"></v-divider>
      <h4 :style="`color : ${wsDARKER}`" class="font-weight-regular" style="font-size: 16px">{{ $t(`homepage.pricing.plans.${plan.value}.text`)  }}</h4>
      <v-divider class="my-6" style="border-width: 1px" :style="`border-color : ${wsBACKGROUND}`"></v-divider>
      <ul style="list-style: square; list-style: none" class="ml-n7" :class="!plan.managers ? 'pb-6' : null">
        <li> <h4 :style="`color : ${wsDARKER}`" class="font-weight-regular" style="font-size: 16px">
          <v-icon :color="wsDARKER">mdi-circle-small</v-icon>
          {{ $tc('course' , plan.courses ) }}
        </h4></li>
        <li> <h4 :style="`color : ${wsDARKER}`"
                 class="font-weight-regular" style="font-size: 16px">
         <v-icon :color="wsDARKER">mdi-circle-small</v-icon>
          {{ plan.students }} {{ $t('students') }}
        </h4></li>
        <li v-if="plan.managers"> <h4 :style="`color : ${wsDARKER}`" class="font-weight-regular" style="font-size: 16px">
          <v-icon :color="wsDARKER">mdi-circle-small</v-icon>
          {{ plan.managers }} {{ $t('managers') }}
        </h4></li>
        <li> <h4 :style="`color : ${wsDARKER}`"
                 class="font-weight-regular" style="font-size: 16px">
         <v-icon :color="wsDARKER">mdi-circle-small</v-icon>
          {{ plan.storage }} GB {{ $t('storage') }}
        </h4></li>
      </ul>

      <ws-button
          :to="!register ? localeLink('register') : null"
          @click="register ? $emit('select' , plan.plan_alias ) : null"
          :loading="LOADING"
          :disabled="LOADING"
          block
      >
        {{ $t('homepage.pricing.tryForFree') }}
      </ws-button>
      <h5 class="mt-2 text-center" :style="`color : ${wsDARKLIGHT}`">{{ $t('homepage.pricing.for30Days') }}</h5>
    </div>

  </v-sheet>
</template>

<script>

export default {
  name: "paymentPlanCard",
  props : {
    plan : {
      type : Object,
      default() { return {} }
    },
    register : {
      type: Boolean,
      default : false
    },
    index : {
      type : Number,
      default : 0
    }
  }
}
</script>

<style scoped>

</style>